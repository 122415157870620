import axios from "axios";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useParams();
  const navigate = useNavigate();

  // Validate new password based on provided rules
  const handleNewPasswordChange = (e) => {
    const passwordInput = e.target.value;
    setNewPassword(passwordInput);

    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$&])[A-Za-z\d@#$&]{10,}$/;
    setIsNewPasswordValid(passwordRegex.test(passwordInput));
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/reset-password`,
        {
          passwordResetToken: token,
          newPassword,
        }
      );
      setMessage("Password reset successfully! Redirecting to login...");
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      if (
        error.response &&
        error.response.data.message === "Password cannot contain Sleek ID."
      ) {
        setErrorMessage(
          "Your password cannot contain your Sleek ID. Please choose a different password."
        );
      } else if (
        error.response &&
        error.response.data.message ===
          "Password cannot be the same as the current password."
      ) {
        setErrorMessage(
          "Your new password cannot be the same as your current password. Please choose a different password."
        );
      } else if (error.response && error.response.status === 410) {
        setErrorMessage("Reset token has expired. Please request a new one.");
      } else {
        setErrorMessage("Error resetting password. Please try again.");
      }
      setMessage("");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-200 p-6">
      <div className="bg-white p-8 shadow-md rounded-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Reset Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-lg mb-1 block">New Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleNewPasswordChange}
                className="border border-gray-400 p-2 w-full rounded-md"
                placeholder="Enter new password"
                required
              />
              <span
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {!isNewPasswordValid && newPassword && (
              <p className="text-sm text-red-500 mt-1">
                The password must be at least 10 characters in length. It must
                include letters, numbers, and at least one of the special
                characters (&, @, #, $).
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="text-lg mb-1 block">Confirm Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="border border-gray-400 p-2 w-full rounded-md"
                placeholder="Confirm your password"
                required
              />
              <span
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {confirmPassword && confirmPassword !== newPassword && (
              <p className="text-sm text-red-500 mt-1">
                It must match the password.
              </p>
            )}
          </div>

          <button
            type="submit"
            className={`bg-blue-500 text-white py-2 px-4 rounded-md w-full ${
              !isNewPasswordValid || !confirmPassword || isLoading
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={!isNewPasswordValid || !confirmPassword || isLoading}
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </button>
        </form>
        {message && <p className="text-green-500 mt-4">{message}</p>}
        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
