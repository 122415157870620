import React from "react";
import { NavLink } from "react-router-dom";

const SettingsMenu = ({ handleProfile, handleCommodities, handleHistory }) => {
  return (
    <div className="w-36 bg-green-200 shadow-lg fixed h-full">
      <div className="p-6">
        <h2 className="text-xl font-bold mb-4">Settings</h2>
        <ul>
          <li className="mb-2">
            <NavLink
              to={"home"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              Home
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to={"profile"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              Profile
            </NavLink>
          </li>
          { /*<li className="mb-2">
            <NavLink
              to={"commodities"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-6 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              } 
            >
             Commodities
            </NavLink>
          </li> */}
          <li className="mb-2">
  <NavLink
    to={"commodities"}
    className={({ isActive }) =>
      isActive
        ? "flex items-center justify-center w-full px-6 py-2 bg-green-400 rounded-md"
        : "flex items-center justify-center w-full px-6 py-2 rounded-md"
    }
  >
    Commodities
  </NavLink>
</li>



  

          <li className="mb-2">
            <NavLink
              to={"history"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              History
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingsMenu;
