import React from "react";
import { useNavigate } from "react-router-dom";
import add from "./add.jpg";
import gallery from "./gallery.jpg";
import cashew from "./Processing.png";

const HomeWorkspace = () => {
  const navigate = useNavigate();

  return (
    <div
      className="p-4 flex flex-col items-start overflow-hidden"
      style={{
        marginTop: "1.5cm", // Adjust top margin to sit below the header
        paddingLeft: "6rem", // Left padding for space from sidebar
        height: "calc(100vh - 5rem)", // Full screen fit without scrolling
      }}
    >
      <h1 className="text-3xl font-bold mb-4">
        Welcome to Krissaco Sleek! What would you like to do today?
      </h1>
      <p className="mb-6 text-gray-700">
        Krissaco Sleek is your comprehensive tool for managing cashew nut
        processing and enhancing business operations. From updating your profile
        to tracking production efficiencies, this platform is designed to
        streamline workflows, save time, and boost productivity. With just a few
        clicks, you can manage your profile, activate processing functions, and
        analyze your performance data to make more informed business decisions.
      </p>

      {/* First Section */}
      <div className="flex items-center mb-6">
        <img
          src={gallery}
          alt="Profile Icon"
          className="w-20 h-20 mr-4 rounded-md object-cover" // Uniform image size
        />
        <div>
          <h2 className="text-xl font-semibold mb-2">
            You can change your profile
          </h2>
          <p className="text-gray-600 mb-2">
            Easily keep your profile up-to-date by updating your personal
            information and preferences. Whether you need to modify contact
            details or adjust account settings, the profile section is designed
            for simple and fast changes, ensuring your account is tailored to
            meet your operational needs.
          </p>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:scale-105 transition-transform duration-200"
            onClick={() => navigate("/workspace/settings/profile")}
          >
            Go to Profile settings
          </button>
        </div>
      </div>

      {/* Second Section */}
      <div className="flex items-center mb-6">
        <img
          src={add}
          alt="Cashew Icon"
          className="w-20 h-20 mr-4 rounded-md object-cover" // Uniform image size
        />
        <div>
          <h2 className="text-xl font-semibold mb-2">
            You can activate Cashew Nut Processing
          </h2>
          <p className="text-gray-600 mb-2">
            Starting your cashew nut processing operations has never been
            easier. With Krissaco Sleek, you can initiate and manage the
            processing flow with a few clicks, ensuring that all critical
            stages, from boiling to sorting, are seamlessly integrated into your
            workflow.
          </p>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:scale-105 transition-transform duration-200"
            onClick={() => navigate("/workspace/settings/commodities")}
          >
            Go to Commodities
          </button>
        </div>
      </div>

      {/* Third Section */}
      <div className="flex items-center">
        <img
          src={cashew}
          alt="Processing Icon"
          className="w-20 h-20 mr-4 rounded-md object-cover" // Uniform image size
        />
        <div>
          <h2 className="text-xl font-semibold mb-2">
            Analyse your Processing Efficiencies
          </h2>
          <p className="text-gray-600 mb-2">
            Gain valuable insights into your operational performance with
            analytics. Krissaco Sleek allows you to monitor key efficiency
            metrics at each stage of cashew nut processing, helping you identify
            bottlenecks and areas for improvement, optimizing your overall
            productivity and profitability.
          </p>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:scale-105 transition-transform duration-200"
            onClick={() => navigate("cashew/dashboard")}
          >
            Go to Cashew Nut Processing
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeWorkspace;
