import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CashewWorkbench from "./components/cashew/CashewWorkbench";
import ForgotPasswordPage from "./components/login/ForgotPasswordPage";
import LoginPage from "./components/login/LoginPage";
import RegistrationForm from "./components/login/RegistrationForm";
import ResetPasswordPage from "./components/login/ResetPasswordPage";
import HomeWorkspace from "./components/main/Homeworkspace";
import SettingsWorkbench from "./components/settings/SettingsWorkbench";
import Workspace from "./components/Workspace";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {/*<Route index element={<DefaultWorkbench />} />*/}
        <Route path="/login/*" element={<LoginPage />}></Route>

        <Route path="/register/*" element={<RegistrationForm />}></Route>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/reset-password/:token/:email"
          element={<ResetPasswordPage />}
        />
        <Route path="/workspace" element={<Workspace />}>
          <Route path="" element={<HomeWorkspace />} />

          <Route path="settings/*" element={<SettingsWorkbench />}></Route>
          <Route path="cashew/*" element={<CashewWorkbench />}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
