import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../main/NavigationBar";
import dashboard from "./dashboard.jpg";
import Flowchart from "./Flowchart.png";
import myImage from "./gallery.jpg";
import insights from "./insights.jpg";

const CommoditySettings = () => {
  const [supportedCommodities, setSupportedCommodities] = useState([]);
  const [activatedCommodities, setActivatedCommodities] = useState([]);
  const [message, setMessage] = useState("");
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [showCashewNutDetails, setShowCashewNutDetails] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [lossThresholds, setLossThresholds] = useState({
    boilingSteaming: 0.0,
    grading: 0.0,
    cutting: 0.0,
    primaryShelling: 0.0,
    secondaryShelling: 0.0,
    bormaDrying: 0.0,
    cooling: 0.0,
    peeling: 0.0,
    sweating: 0.0,
    sorting: 0.0,
    packaging: 0.0,
  });
  const [isCashewNutActivated, setIsCashewNutActivated] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const getSleekId = () => {
    return localStorage.getItem("sleekId");
  };

  useEffect(() => {
    const fetchCommodities = async () => {
      try {
        const token = getToken();
        const sleekId = getSleekId();

        if (!token || !sleekId) {
          setMessage("Sleek ID or token not found. Please log in again.");
          return;
        }

        const [supportedResponse, activatedResponse] = await Promise.all([
          fetch(
            `${process.env.REACT_APP_API_URL}/api/commodities/support-commodities`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          fetch(
            `${process.env.REACT_APP_API_URL}/api/commodities/activated-commodities`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        if (!supportedResponse.ok || !activatedResponse.ok) {
          throw new Error("Failed to fetch commodities.");
        }

        const supportedData = await supportedResponse.json();
        const activatedData = await activatedResponse.json();

        setSupportedCommodities(supportedData);
        setActivatedCommodities(activatedData);

        setIsCashewNutActivated(activatedData.includes("Cashew Nut"));
      } catch (error) {
        console.error("Error fetching commodities:", error);
        setMessage("Failed to fetch commodities.");
      }
    };

    const fetchLossThresholds = async () => {
      try {
        const token = getToken();
        const sleekId = getSleekId();

        if (!token || !sleekId) {
          setMessage("Sleek ID or token not found. Please log in again.");
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch loss thresholds.");
        }

        const data = await response.json();
        setLossThresholds(data);
      } catch (error) {
        console.error("Error fetching loss thresholds:", error);
        setMessage("Failed to fetch loss thresholds.");
      }
    };

    fetchCommodities();
    fetchLossThresholds();
  }, []);

  const handleSettingsToggle = () => {
    setShowSettingsMenu((prev) => !prev);
  };

  const handleProfile = () => {
    setShowSettingsMenu(true);
    navigate("/dashboard/profile");
  };

  const handleCommodities = () => {
    setShowSettingsMenu(true);
    navigate("/dashboard/commodity-settings");
  };

  const handleHistory = () => {
    setShowSettingsMenu(true);
    navigate("/dashboard/history");
  };

  const handleCashewNutClick = () => {
    setShowCashewNutDetails(true);
  };

  const handleLossThresholdChange = (process, value) => {
    const parsedValue = parseFloat(value);
    setLossThresholds((prev) => ({ ...prev, [process]: parsedValue }));
  };

  const handleSaveThresholds = async () => {
    try {
      const token = getToken();
      const sleekId = getSleekId();

      if (!token || !sleekId) {
        setMessage("Sleek ID or token not found. Please log in again.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(lossThresholds),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update loss thresholds.");
      }

      setIsEditMode(false);
    } catch (error) {
      console.error("Error updating loss thresholds:", error);
      setMessage("Failed to update loss thresholds.");
    }
  };

  const handleResetLossThresholds = async () => {
    try {
      const token = getToken();
      const sleekId = getSleekId();

      if (!token || !sleekId) {
        setMessage("Sleek ID or token not found. Please log in again.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages/reset`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reset loss thresholds.");
      }

      const data = await response.json();
      setLossThresholds(data);
    } catch (error) {
      console.error("Error resetting loss thresholds:", error);
      setMessage("Failed to reset loss thresholds.");
    }
  };

  const handleActivateToggle = () => {
    if (activatedCommodities.includes("Cashew Nut")) {
      setActivatedCommodities((prev) =>
        prev.filter((commodity) => commodity !== "Cashew Nut")
      );
    } else {
      setActivatedCommodities((prev) => [...prev, "Cashew Nut"]);
    }
  };

  const disableScrollOnNumberInput = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex ml-36">
      <div className="flex flex-col flex-1">
        <div className="flex flex-1">
          <div className="flex-1 gap-8 flex flex-col overflow-y-auto">
            {showCashewNutDetails ? (
              <div className="flex">
                {/* Main Content */}
                <div className="flex-1 bg-white p-6 rounded mr-80">
                  <h1 className="text-2xl font-bold mb-4">
                    Manage Cashew Nut Processing
                  </h1>
                  <p className="mb-6">
                    Efficiently manage each phase of your cashew nut processing
                    workflow, from Boiling to Packaging Stages, through Krissaco
                    Sleek. This feature enables you to monitor, streamline, and
                    optimize resources across the entire process. Maintaining
                    high standards of quality at each stage helps to ensure a
                    seamless operation that maximizes both productivity and
                    product quality.
                  </p>

                  {/* Flowchart and First Three Stages */}
                  <div className="flex mb-6">
                    {/* First Three Stages */}
                    <div className="flex-1">
                      {/* Boiling/Steaming Section */}
                      <div className="mb-6">
                        <h2 className="text-xl font-semibold">
                          Boiling/Steaming
                        </h2>
                        <p className="mb-2">
                          The boiling or steaming phase softens the raw cashew
                          nut shells, making them easier to crack without
                          damaging the kernel inside. This heat treatment is
                          crucial for preparing the nuts for the shelling
                          process, preserving the quality of the kernel while
                          ensuring efficient shell removal.
                        </p>
                        <div className="flex items-center mb-4">
                          <span className="mr-2">Loss Threshold</span>
                          <input
                            type="number"
                            value={lossThresholds.boilingSteaming}
                            onChange={(e) =>
                              handleLossThresholdChange(
                                "boilingSteaming",
                                e.target.value
                              )
                            }
                            onWheel={disableScrollOnNumberInput}
                            className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                            disabled={!isEditMode}
                          />
                          <span className="ml-2">%</span>
                        </div>
                      </div>

                      {/* Grading Section */}
                      <div className="mb-6">
                        <h2 className="text-xl font-semibold">Grading</h2>
                        <p className="mb-2">
                          In the grading stage, cashew nuts are sorted according
                          to size, weight, and quality. This ensures uniform
                          processing and helps maximize efficiency during
                          subsequent steps, like cutting and shelling, by
                          grouping similar nuts together for optimal treatment.
                        </p>
                        <div className="flex items-center mb-4">
                          <span className="mr-2">Loss Threshold</span>
                          <input
                            type="number"
                            value={lossThresholds.grading}
                            onChange={(e) =>
                              handleLossThresholdChange(
                                "grading",
                                e.target.value
                              )
                            }
                            onWheel={disableScrollOnNumberInput}
                            className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                            disabled={!isEditMode}
                          />
                          <span className="ml-2">%</span>
                        </div>
                      </div>

                      {/* Cutting Section */}
                      <div className="mb-6">
                        <h2 className="text-xl font-semibold">Cutting</h2>
                        <p className="mb-2">
                          Cutting involves carefully splitting open the softened
                          cashew shells to access the kernel. Precision is key
                          here to avoid damaging the delicate kernel inside.
                          Accurate cutting helps maintain a high yield and
                          ensures the quality of the final product.
                        </p>
                        <div className="flex items-center mb-4">
                          <span className="mr-2">Loss Threshold</span>
                          <input
                            type="number"
                            value={lossThresholds.cutting}
                            onChange={(e) =>
                              handleLossThresholdChange(
                                "cutting",
                                e.target.value
                              )
                            }
                            onWheel={disableScrollOnNumberInput}
                            className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                            disabled={!isEditMode}
                          />
                          <span className="ml-2">%</span>
                        </div>
                      </div>
                    </div>

                    {/* Flowchart Image */}
                    <div className="w-1/2 pl-6">
                      <img
                        src={Flowchart}
                        alt="Process Flowchart"
                        className="w-full h-auto object-contain"
                      />
                    </div>
                  </div>

                  {/* Rest of the stages */}
                  {/* Primary Shelling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Primary Shelling</h2>
                    <p className="mb-2">
                      Primary shelling is the process of removing the outer
                      shell from the cashew nut. This can be done manually or
                      mechanically, and it's the first step in fully exposing
                      the edible cashew kernel for further processing, ensuring
                      minimal damage to the kernel.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.primaryShelling}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "primaryShelling",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Secondary Shelling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">
                      Secondary Shelling
                    </h2>
                    <p className="mb-2">
                      In this stage, any remaining shell fragments left after
                      primary shelling are removed here. Secondary shelling
                      ensures that the kernel is completely free from shell
                      debris before moving on to the next processing steps,
                      resulting in a cleaner and higher-quality product.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.secondaryShelling}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "secondaryShelling",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>
                  {/* Borma Drying Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Borma Drying</h2>
                    <p className="mb-2">
                      Borma drying is essential for reducing the moisture
                      content of the cashew kernels after shelling. This
                      controlled drying process improves the shelf life of the
                      kernels, enhances their texture, and prepares them for
                      long-term storage or further processing.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.bormaDrying}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "bormaDrying",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Cooling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Cooling</h2>
                    <p className="mb-2">
                      Once dried, the cashew kernels are chilled to make them
                      more brittle. This facilitates easier peeling in the next
                      stage and helps to reduce the risk of kernel damage while
                      removing the outer skin efficiently.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.cooling}
                        onChange={(e) =>
                          handleLossThresholdChange("cooling", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Peeling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Peeling</h2>
                    <p className="mb-2">
                      Peeling removes the thin, outer skin from the cashew
                      kernel after it has been chilled. This is a crucial step
                      for achieving the desired appearance and quality standards
                      before the kernels are sorted and prepared for sale.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.peeling}
                        onChange={(e) =>
                          handleLossThresholdChange("peeling", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Sweating Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Sweating</h2>
                    <p className="mb-2">
                      After peeling, the cashew kernels undergo a sweating
                      process, allowing them to balance their moisture content
                      under controlled conditions. This ensures that the kernels
                      achieve the ideal texture for packaging and consumption,
                      enhancing their quality and appeal.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.sweating}
                        onChange={(e) =>
                          handleLossThresholdChange("sweating", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Sorting Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Sorting</h2>
                    <p className="mb-2">
                      Sorting categorizes the peeled kernels based on size,
                      shape, and color to ensure consistency and quality. This
                      stage determines the final product's grade, affecting its
                      market value and ensuring compliance with industry
                      standards.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.sorting}
                        onChange={(e) =>
                          handleLossThresholdChange("sorting", e.target.value)
                        }
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Packaging Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Packaging</h2>
                    <p className="mb-2">
                      Packaging is the final step where the sorted cashew
                      kernels are securely packed for storage and
                      transportation. Proper packaging helps protect the kernels
                      from contamination and moisture, preserving their
                      freshness and quality until they reach consumers.
                    </p>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.packaging}
                        onChange={(e) =>
                          handleLossThresholdChange("packaging", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Edit/Save Button */}
                  <div className="flex space-x-4">
                    <button
                      onClick={() =>
                        isEditMode
                          ? handleSaveThresholds()
                          : setIsEditMode(true)
                      }
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-400"
                    >
                      {isEditMode ? "Save" : "Edit"}
                    </button>
                    <button
                      onClick={handleResetLossThresholds}
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-400"
                      disabled={isEditMode}
                    >
                      Reset Loss Thresholds
                    </button>
                    {/**
                    <button
                      onClick={handleActivateToggle}
                      className={`px-4 py-2 text-white rounded ${
                        activatedCommodities.includes("Cashew Nut")
                          ? "bg-red-500 hover:bg-red-400"
                          : "bg-green-500 hover:bg-green-400"
                      }`}
                      disabled={isEditMode}
                    >
                      {activatedCommodities.includes("Cashew Nut")
                        ? "Deactivate Cashew Nut Processing"
                        : "Activate Cashew Nut Processing"}
                    </button>
                     */}
                  </div>

                  {/* NavigationBar Component */}
                  <div>
                    <NavigationBar
                      activatedCommodities={activatedCommodities}
                    />
                  </div>
                </div>

                {/* Right Sidebar for Commodities */}
                <div className="fixed right-0 top-16 bg-gray-200 w-80 h-[calc(100vh-4rem)] p-6 overflow-y-auto">
                  <h2 className="text-2xl font-bold mb-4">
                    Supported Commodities
                  </h2>
                  <ul className="mb-4">
                    {supportedCommodities.length > 0 ? (
                      supportedCommodities.map((commodity, index) => (
                        <li key={index} className="text-gray-700">
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <li className="text-gray-700">Cashew Nut</li>
                    )}
                  </ul>
                  {/**
                  <h2 className="text-2xl font-bold mb-4">
                    Activated Commodities
                  </h2>
                  <ul>
                    {activatedCommodities.length > 0 ? (
                      activatedCommodities.map((commodity, index) => (
                        <li key={index} className="text-gray-700">
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <p className="text-gray-500">None</p>
                    )}
                  </ul>
                   */}
                </div>
              </div>
            ) : (
              <div className="flex flex-1">
                <div className="flex-1 bg-white p-6 rounded mr-80">
                  <h1 className="text-2xl font-bold mb-4">
                    Manage Commodity Processing
                  </h1>
                  <p></p>
                  <div>
                    <div className="mb-6">
                      <div className="flex items-center">
                        <img
                          src={myImage}
                          alt="Profile"
                          className="w-20 h-20 mr-4"
                        />
                        <div>
                          <h2 className="text-lg font-semibold">Data</h2>
                          <p>
                            This section of the app allows you to input and
                            store crucial information about your processing
                            operations, such as raw material quantities, dates,
                            and output quantities while automatically recording
                            the processing times. By concentrating on entering
                            data, you can make sure everything is recorded
                            correctly and is easy to find later. The information
                            you enter is shown separately in reports, helping
                            you keep your records organized. This feature makes
                            data entry easier, allowing you to manage and
                            monitor your operations more effectively.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="flex items-center">
                        <img
                          src={dashboard}
                          alt="dashboard"
                          className="w-20 h-20 mr-4"
                        />
                        <div>
                          <h2 className="text-lg font-semibold">Dashboard</h2>
                          <p>
                            The dashboard feature gives users a clear and
                            current view of their operations for a chosen date
                            through a personalized interface. It combines and
                            organizes data in a simple format, showing it in an
                            easy-to-understand way. Users can see information
                            through charts, graphs, and quick number insights,
                            which help them quickly check important performance
                            measures. This up-to-date view allows users to make
                            quick changes to their processes when needed,
                            improving how well everything runs.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="flex items-center">
                        <img
                          src={insights}
                          alt="insights"
                          className="w-20 h-20 mr-4"
                        />
                        <div>
                          <h2 className="text-lg font-semibold">Insights</h2>
                          <p>
                            The insights feature provides useful information
                            about cashew processing to help you improve your
                            operations. It analyzes how efficient different
                            processing methods work and identifies areas for
                            improvement. By offering practical recommendations,
                            this feature helps you make smart decisions that
                            boost productivity and reduce time and resource
                            wastage in your processing activities.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fixed right-0 top-16 bg-gray-200 w-80 h-[calc(100vh-4rem)] p-6 overflow-y-auto">
                  <h2 className="text-2xl font-bold mb-4">
                    Supported Commodities
                  </h2>
                  <ul className="mb-4">
                    {supportedCommodities.length > 0 ? (
                      supportedCommodities.map((commodity, index) => (
                        <li
                          key={index}
                          onClick={handleCashewNutClick}
                          className="text-gray-700 cursor-pointer hover:bg-gray-200 p-2 rounded"
                        >
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <li
                        onClick={handleCashewNutClick}
                        className="to-black cursor-pointer hover:bg-gray-300 p-2 rounded"
                      >
                        Cashew Nut
                      </li>
                    )}
                  </ul>
                  {/**
                  <h2 className="text-2xl font-bold mb-4">
                    Activated Commodities
                  </h2>
                  <ul>
                    {activatedCommodities.length > 0 ? (
                      activatedCommodities.map((commodity, index) => (
                        <li key={index} className="text-gray-700">
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <p className="text-gray-500">None</p>
                    )}
                  </ul>
                   */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommoditySettings;
