import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // Handle email validation and input changes
  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    // Simple regex for email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.(com|in|org|net)$/i;
    setIsEmailValid(emailRegex.test(emailInput));
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/forgot-password`,
        { email },
        {
          headers: {
            "X-Frontend-Base-Url": window.location.origin,
          },
        }
      );
      setMessage("Password reset link sent to your email!");
      setErrorMessage("");
    } catch (error) {
      // Specific error for non-existing email
      if (error.response && error.response.status === 404) {
        setErrorMessage("The email does not exist.");
      } else {
        setErrorMessage("Error sending password reset link. Please try again.");
      }
      setMessage("");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-200 p-6">
      <div className="bg-white p-8 shadow-md rounded-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Forgot Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-lg mb-1 block">
              Enter your registered email
            </label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="border border-gray-400 p-2 w-full rounded-md"
              placeholder="Enter your email"
              required
            />
            {!isEmailValid && email && (
              <p className="text-sm text-red-500 mt-1">
                Please enter a valid email (e.g., .com, .in, etc.).
              </p>
            )}
          </div>
          <button
            type="submit"
            className={`bg-blue-500 text-white py-2 px-4 rounded-md w-full ${
              !isEmailValid || isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!isEmailValid || isLoading}
          >
            {isLoading ? "Sending..." : "Send Reset Link"}
          </button>
        </form>
        {message && <p className="text-green-500 mt-4">{message}</p>}
        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
