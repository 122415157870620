import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  const [workspaceName, setWorkspaceName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(null); // State for profile image
  const [lastLogin, setLastLogin] = useState("unknown"); // State for last login (default to 'unknown')
  const [lastLoginIp, setLastLoginIp] = useState("unknown"); // State for last login IP address
  const [isLoggingOut, setIsLoggingOut] = useState(false); // State to manage logout text

  // Helper function to get the first characters of the workspace name
  function getFirstCharacters(str) {
    if (!str) {
      return "";
    }
    const words = str.trim().split(/\s+/); // Split workspace name into words
    const firstChar = words[0][0]; // First character of the first word
    let secondChar = "";
    if (words.length > 1) secondChar = words[1][0]; // First character of the second word if it exists
    return (firstChar + secondChar).toUpperCase(); // Return initials in uppercase
  }

  // useEffect to fetch the profile data on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");
      const sleekId = localStorage.getItem("sleekId");

      // Redirect to login if no token is found
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        // Fetch subscription details using the sleekId
        const detailsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions/${sleekId}/details`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send token for authorization
            },
          }
        );

        if (detailsResponse.status === 200) {
          setWorkspaceName(detailsResponse.data.workspaceName); // Set workspace name
          setEmail(detailsResponse.data.email); // Set email
        } else {
          navigate("/login"); // Redirect to login if the request fails
        }

        // Fetch profile image
        const profileImageResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions/${sleekId}/profile-image`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send token for authorization
            },
            responseType: "blob", // Make sure we get binary data for the image
          }
        );

        // Check if the image has content before setting the URL
        if (
          profileImageResponse.status === 200 &&
          profileImageResponse.data.size > 0
        ) {
          const imageUrl = URL.createObjectURL(profileImageResponse.data); // Create URL for the image blob
          setProfileImageUrl(imageUrl); // Set the profile image URL
        }

        // Fetch last login history
        const loginHistoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/history/last-login/${sleekId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send token for authorization
            },
          }
        );

        // If login history is found, set last login time and IP address
        if (loginHistoryResponse.status === 200) {
          const lastLoginData = loginHistoryResponse.data;
          const lastLoginTime = new Date(
            lastLoginData.timestamp
          ).toLocaleString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            day: "numeric",
            month: "long",
            year: "numeric",
          }); // Convert to readable format
          setLastLogin(lastLoginTime); // Set last login time
          setLastLoginIp(lastLoginData.ipAddress); // Set last login IP address from History entity
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        // Do not redirect to login, but keep 'unknown' as default values for last login
      }
    };

    fetchProfile(); // Call the fetchProfile function on component mount
  }, [navigate]);

  // Logout function
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    const sleekId = localStorage.getItem("sleekId");

    // Redirect to login if no token is found
    if (!token) {
      navigate("/login");
      return;
    }

    setIsLoggingOut(true); // Show "Logging out..." text when logout is initiated

    try {
      // Make logout request
      await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/${sleekId}/logout`,
        { token: token }, // Pass the token in the body of the request
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token for authorization
          },
        }
      );
      // Remove the token and sleekId from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("sleekId");

      // Redirect to home page after successful logout
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error); // Log error if logout fails
      setIsLoggingOut(false); // Reset logout text if logout fails
    }
  };

  return (
    <div className="header h-16 bg-white border-b fixed top-0 left-20 right-0 flex items-center p-5 z-10">
      <div className="flex items-center">
        {profileImageUrl ? (
          // Display profile image if available
          <img
            src={profileImageUrl}
            alt="Profile"
            className="w-12 h-12 rounded-full object-cover"
          />
        ) : (
          // Fallback: Display initials if no profile image is found
          <div className="flex items-center justify-center w-12 h-12 bg-black text-white rounded-full text-2xl">
            {getFirstCharacters(workspaceName)}{" "}
            {/* Display workspace initials */}
          </div>
        )}
        <h1 className="pl-3 text-2xl font-bold">{workspaceName}</h1>{" "}
        {/* Display workspace name */}
      </div>
      <div className="ml-auto flex items-center space-x-4">
        <div className="text-xs text-gray-600">
          Your Sleek ID is {localStorage.getItem("sleekId")} and is connected to{" "}
          {email}
          <br />
          Your last login was at {lastLogin} from I.P Address:{lastLoginIp}
        </div>
        <button
          className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 mr-4 rounded transition duration-200"
          onClick={handleLogout}
          disabled={isLoggingOut} // Disable the button while logging out
        >
          {isLoggingOut ? "Logging out..." : "Logout"}{" "}
          {/* Conditionally display text */}
        </button>
      </div>
    </div>
  );
};

export default Banner;
