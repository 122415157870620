import React from "react";
import { NavLink } from "react-router-dom";
//import { Link } from "react-router-dom";
const CashewNutMenu = () => {
  return (
    <div className="w-36 bg-green-200 fixed h-full">
      <div className="p-6">
        <h2 className="text-xl font-bold mb-4">Cashew Nut</h2>
        <ul>
          <li className="mb-2">
            <NavLink
              to={"dashboard"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to={"insights"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              Insights
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to={"data"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              Data
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to={"reports"}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center px-4 py-2 bg-green-400 rounded"
                  : "flex items-center px-4 py-2"
              }
            >
              Reports
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CashewNutMenu;
