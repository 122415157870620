import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons from react-icons

const RegistrationForm = () => {
  let initData = {
    workspaceName: "",
    email: "",
    subscriptionId: "",
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(initData);
  const [errors, setErrors] = useState({
    workspaceName: false,
    subscriptionId: false,
    email: false,
    password: false,
    confirmPassword: false
  });
  const [generalErrorMessage, setGeneralErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    let errorFields = {
      workspaceName: false,
      subscriptionId: false,
      email: false,
      password: false,
      confirmPassword: false
    }
    if (formData.workspaceName === null || formData.workspaceName === "")
      errorFields.workspaceName = true;

    if (!/^[a-zA-Z0-9]{8,32}$/.test(formData.subscriptionId))
      errorFields.subscriptionId = true;

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email))
      errorFields.email = true;

    if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$#@&])[A-Za-z\d$#@&]{10}$/.test(formData.password))
      errorFields.password = true;

    if (typeof formData.password === 'string' && formData.password.includes(formData.subscriptionId))
      errorFields.password = true;

    if (formData.password !== formData.confirmPassword)
      errorFields.confirmPassword = true;

    setErrors(errorFields);
    setSuccessMessage("");
    setGeneralErrorMessage("");
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setGeneralErrorMessage("");
    setSuccessMessage("");
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/subscriptions/register`, {
        workspaceName: formData.workspaceName,
        email: formData.email,
        sleekId: formData.subscriptionId,
        password: formData.password,
      });

      setSuccessMessage(
        "Registration successful! Please check your email for the activation link."
      );
      setGeneralErrorMessage("");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setSuccessMessage("");
        setGeneralErrorMessage("Sleek ID or Email already exists.");
      } else {
        setSuccessMessage("");
        setGeneralErrorMessage("Registration failed. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setFormData({ ...initData });
    setGeneralErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 shadow-lg rounded-lg max-w-2xl w-full">
        <h1 className="text-2xl sm:text-3xl font-bold font-sans text-center">
          Register with Sleek
        </h1>
        <p className="text-lg sm:text-xl mb-4 font-sans text-center">
          Start your journey towards efficient processing
        </p>
        {generalErrorMessage && (
          <p className="mt-4 font-sans text-center text-red-500">{generalErrorMessage}</p>
        )}
        {successMessage && (
          <p className="text-green-500 mt-4 text-center font-sans">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-lg font-sans mb-0 block">
              Tell the name of your organization{" "}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="workspaceName"
              value={formData.workspaceName}
              onChange={handleChange}
              className="border border-gray-400 p-2 w-full rounded-md h-8"
              maxLength={32}
              required
            />
            <p className={`text-sm ${errors.workspaceName ? 'text-red-600 italic' : 'text-gray-600'} mt-1 font-sans`}>
              Give the name for up to 32 characters.
            </p>
          </div>
          <div className="mb-4">
            <label className="text-lg font-sans mb-0 block">
              Choose a unique ID for your Sleek account{" "}
              <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <input
                type="text"
                name="subscriptionId"
                value={formData.subscriptionId}
                onChange={handleChange}
                className={`border border-gray-400 p-2 w-full rounded-md  h-8`}
                required
              />
            </div>
            <p className={`text-sm ${errors.subscriptionId ? 'text-red-600 italic' : 'text-gray-600'} text-gray-600 mt-1 font-sans`}>
              Must be 8-32 characters long, consisting only alphanumerics
            </p>
          </div>
          <div className="mb-4">
            <label className="text-lg font-sans mb-0 block">
              Enter an email ID for activation
              <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border border-gray-400 p-2 w-full rounded-md h-8"
              required
            />
            <p className={`text-sm ${errors.workspaceName ? 'text-red-600 italic' : 'text-gray-600'} mt-1 font-sans`}>
              Sleek activation link will be mailed to this address.
            </p>
          </div>

          <div className="mb-4 relative">
            <label className="text-lg font-sans mb-0 block">
              Set up a password <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="border border-gray-400 p-2 w-full rounded-md h-8"
                required
              />
              <span
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              <p className={`text-sm ${errors.password ? 'text-red-600 italic' : 'text-gray-600'} mt-1 font-sans`}>
                The password must be at least 10 characters in length. It must
                include letters, numbers, and at least one of the special
                characters (&, @, #, $). Your Sleek ID must not be part of the
                password.
              </p>
            </div>
          </div>
          <div className="mb-4 relative">
            <label className="text-lg font-sans mb-0 block">
              Confirm Password <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="border border-gray-400 p-2 w-full rounded-md h-8"
                required
              />
              <span
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <p className={`text-sm ${errors.confirmPassword ? 'text-red-600 italic' : 'text-gray-600'} mt-1 font-sans`}>
              It must match the password
            </p>
          </div>
          <div className="flex justify-between">
            <button
              type="submit"
              className={`${isLoading || successMessage.length>0 || Object.values(errors).includes(true) ? 'bg-gray-500' : 'bg-green-500'} text-white py-2 px-4 rounded-md`}
              disabled={isLoading || successMessage.length>0 || Object.values(errors).includes(true)}
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
            <button
              type="button"
              onClick={handleClear}
              className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md"
            >
              Clear
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
