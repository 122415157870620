import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useCallback, useEffect, useState } from "react";
import ExportIcon from "../images/Export button.png";
import MailIcon from "../images/Mail icon.png";
import PdfIcon from "../images/PDF icon.png";
import InsightsGraph from "./InsightsGraph";

const calculateEfficiency = (input, output, lossThreshold) => {
  const safeInput = input || 0;
  const safeOutput = output || 0;
  const safeLossThreshold = lossThreshold || 0;
  const total = safeInput;
  const realized = safeOutput;
  const expected = total * (1 - safeLossThreshold / 100);
  return { total, realized, expected };
};

const calculateLossPercentage = (input, output) => {
  const loss = input - output;
  return (loss / input) * 100;
};

const stages = [
  { value: "all", label: "All Stages" },
  { value: "overallEfficiency", label: "Overall Efficiency" },
  { value: "boilingSteamingInput", label: "Boiling/Steaming" },
  { value: "gradingInput", label: "Grading" },
  { value: "cuttingInput", label: "Cutting" }, // Ensure Cutting stage is included
  { value: "primaryShellingInput", label: "Primary Shelling" },
  { value: "secondaryShellingInput", label: "Secondary Shelling" },
  { value: "bormaDryingInput", label: "Borma Drying" },
  { value: "coolingInput", label: "Cooling" },
  { value: "peelingInput", label: "Peeling" },
  { value: "sweatingInput", label: "Sweating" },
  { value: "sortingInput", label: "Sorting" },
  { value: "packagingInput", label: "Packaging" },
];

const CashewInsights = () => {
  const [processDataList, setProcessDataList] = useState([]);
  const [stagesDataList, setStagesDataList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cumulativeOverallData, setCumulativeOverallData] = useState({});
  const [cumulativeStageData, setCumulativeStageData] = useState({});
  const [lowestEfficiencyStage, setLowestEfficiencyStage] = useState("");
  const [autoFetch, setAutoFetch] = useState(true);

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setStartDate(oneMonthAgo.toISOString().split("T")[0]);
    setEndDate(today);
  }, []);

  const handleFetchData = useCallback(async () => {
    if (startDate && endDate) {
      setLoading(true);
      try {
        const token = getToken();
        const sleekId = getSleekId();

        if (!token || !sleekId) {
          setError("Sleek ID or token not found. Please log in again.");
          return;
        }

        const processingResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${sleekId}/processing/reports?startDate=${startDate}&endDate=${endDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const stagesResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!processingResponse.ok || !stagesResponse.ok) {
          throw new Error(
            `Processing API status: ${processingResponse.status} ${processingResponse.statusText}, Stages API status: ${stagesResponse.status} ${stagesResponse.statusText}`
          );
        }

        const processingData = await processingResponse.json();
        const stagesData = await stagesResponse.json();

        setProcessDataList(processingData || []);
        setStagesDataList(Array(processingData.length).fill(stagesData || {}));
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(`Failed to fetch data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please select a valid date range.");
    }
  }, [startDate, endDate]);

  const getToken = () => localStorage.getItem("token");
  const getSleekId = () => localStorage.getItem("sleekId");

  useEffect(() => {
    if (autoFetch && startDate && endDate) {
      handleFetchData();
    }
  }, [startDate, endDate, autoFetch, handleFetchData]);

  useEffect(() => {
    if (processDataList.length > 0 && stagesDataList.length > 0) {
      const cumulativeOverallData = {
        input: 0,
        output: 0,
        loss: 0,
        expected: 0,
      };

      // Map through stages to ensure we get keys dynamically for cumulativeStageData
      const cumulativeStageData = {};
      let lowestEfficiency = Number.POSITIVE_INFINITY;

      stages.forEach((stage) => {
        if (stage.value === "all" || stage.value === "overallEfficiency")
          return;

        const stageKey = stage.value.replace("Input", "");
        const inputKey = `${stageKey}Input`;
        const outputKey = `${stageKey}Output`;

        cumulativeStageData[stageKey] = processDataList.reduce(
          (acc, processData) => {
            const input = processData[inputKey] || 0;
            const output = processData[outputKey] || 0;
            const lossThreshold = stagesDataList[0][stageKey] || 0;

            acc.input += input;
            acc.realized += output;
            acc.expected = acc.input * (1 - lossThreshold / 100);
            acc.loss = acc.input - acc.realized;
            acc.lossPercent = acc.input > 0 ? (acc.loss / acc.input) * 100 : 0;
            return acc;
          },
          { input: 0, realized: 0, expected: 0, loss: 0, lossPercent: 0 }
        );

        const efficiency =
          cumulativeStageData[stageKey].input > 0
            ? (cumulativeStageData[stageKey].realized /
                cumulativeStageData[stageKey].input) *
              100
            : 0;

        if (efficiency < lowestEfficiency && efficiency > 0) {
          lowestEfficiency = efficiency;
          setLowestEfficiencyStage(stageKey);
        }
      });

      // Calculate cumulative overall data after each stage is aggregated
      processDataList.forEach((processData) => {
        cumulativeOverallData.input += processData?.boilingSteamingInput || 0;
        cumulativeOverallData.output += processData?.packagingOutput || 0;
      });

      const totalLossThreshold = Object.keys(stagesDataList[0] || {}).reduce(
        (acc, stage) => acc + (stagesDataList[0][stage] || 0),
        0
      );

      cumulativeOverallData.expected =
        cumulativeOverallData.input * (1 - totalLossThreshold / 100);
      cumulativeOverallData.loss =
        cumulativeOverallData.input - cumulativeOverallData.output;

      setCumulativeOverallData(cumulativeOverallData);
      setCumulativeStageData(cumulativeStageData);
    }
  }, [processDataList, stagesDataList]);

  const prepareEmailContent = () => {
    const overallEfficiency =
      (cumulativeOverallData.output / cumulativeOverallData.input) * 100 || 0;
    const overallLossPercent =
      ((cumulativeOverallData.input - cumulativeOverallData.output) /
        cumulativeOverallData.input) *
        100 || 0;

    let emailContent = `Cashew Nut Processing Insights Report (${startDate} to ${endDate})\n\n`;
    emailContent += `Overall Efficiency:\n`;
    emailContent += `  Input: ${cumulativeOverallData.input}\n`;
    emailContent += `  Expected Output: ${cumulativeOverallData.expected}\n`;
    emailContent += `  Realized Output: ${cumulativeOverallData.output}\n`;
    emailContent += `  Loss: ${cumulativeOverallData.loss}\n`;
    emailContent += `  Loss (%): ${overallLossPercent.toFixed(2)}%\n`;
    emailContent += `  Efficiency: ${overallEfficiency.toFixed(2)}%\n`;
    emailContent += `  Major Concern: ${lowestEfficiencyStage}\n\n`;

    stages.forEach((stage) => {
      if (stage.value !== "overallEfficiency" && stage.value !== "all") {
        const stageData =
          cumulativeStageData[stage.value.replace("Input", "")] || {};
        const stageEfficiency =
          (stageData.realized / stageData.input) * 100 || 0;
        const stageLossPercent = (stageData.loss / stageData.input) * 100 || 0;

        emailContent += `${stage.label}:\n`;
        emailContent += `  Input: ${stageData.input || 0}\n`;
        emailContent += `  Expected Output: ${stageData.expected || 0}\n`;
        emailContent += `  Realized Output: ${stageData.realized || 0}\n`;
        emailContent += `  Loss: ${stageData.loss || 0}\n`;
        emailContent += `  Loss (%): ${stageLossPercent.toFixed(2)}%\n`;
        emailContent += `  Efficiency: ${stageEfficiency.toFixed(2)}%\n\n`;
      }
    });

    return emailContent;
  };

  const handleSendEmail = async (event) => {
    event.preventDefault();

    try {
      const token = getToken();
      const sleekId = getSleekId();

      if (!token || !sleekId) {
        setError("Sleek ID or token not found. Please log in again.");
        return;
      }

      const emailBody = prepareEmailContent();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/emails/send-mail?sleekId=${sleekId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject: "Cashew Nut Processing Dashboard Insights",
            text: emailBody,
          }),
        }
      );

      if (response.ok) {
        alert("Email sent successfully!");
      } else {
        const errorResponse = await response.json();
        alert(
          `Failed to send email: ${
            errorResponse.message || "Please try again."
          }`
        );
      }
    } catch (error) {
      alert("An unexpected error occurred.");
    }
  };

  const handleExportPDF = useCallback(() => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    const title = `Cashew Nut Processing Insights (${startDate} to ${endDate})`;
    doc.setFontSize(20);
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, 20);

    doc.setFontSize(14);
    const subtitle = "Overall and Stage-wise Efficiency Data Table";
    const subtitleWidth = doc.getTextWidth(subtitle);
    doc.text(subtitle, (pageWidth - subtitleWidth) / 2, 30);

    const tableRows = [];

    const overallEfficiency =
      cumulativeOverallData.input > 0
        ? (cumulativeOverallData.output / cumulativeOverallData.input) * 100
        : 0;
    const overallLossPercent =
      cumulativeOverallData.input > 0
        ? ((cumulativeOverallData.input - cumulativeOverallData.output) /
            cumulativeOverallData.input) *
          100
        : 0;

    tableRows.push([
      "Overall Efficiency",
      cumulativeOverallData.input || 0,
      cumulativeOverallData.expected || 0,
      cumulativeOverallData.output || 0,
      cumulativeOverallData.loss || 0,
      `${overallLossPercent.toFixed(2)}%`,
      `${overallEfficiency.toFixed(2)}%`,
      lowestEfficiencyStage,
    ]);

    stages.forEach((stage) => {
      if (stage.value !== "overallEfficiency" && stage.value !== "all") {
        const stageData =
          cumulativeStageData[stage.value.replace("Input", "")] || {};
        const stageEfficiency =
          stageData.input > 0
            ? (stageData.realized / stageData.input) * 100
            : 0;
        const stageLossPercent =
          stageData.input > 0 ? (stageData.loss / stageData.input) * 100 : 0;

        tableRows.push([
          stage.label,
          stageData.input || 0,
          stageData.expected || 0,
          stageData.realized || 0,
          stageData.loss || 0,
          `${stageLossPercent.toFixed(2)}%`,
          `${stageEfficiency.toFixed(2)}%`,
          "",
        ]);
      }
    });

    doc.autoTable({
      head: [
        [
          "Stage",
          "Input",
          "Expected Output",
          "Realized Output",
          "Loss",
          "Loss (%)",
          "Efficiency (%)",
          "Major Concern",
        ],
      ],
      body: tableRows,
      startY: 40,
      theme: "striped",
      margin: { top: 50 },
    });

    doc.save("Cashew_Nut_Processing_Insights_Report.pdf");
  }, [
    cumulativeOverallData,
    cumulativeStageData,
    lowestEfficiencyStage,
    startDate,
    endDate,
  ]);

  const handleExportData = () => {
    const csvRows = [];

    csvRows.push(
      [
        "Stage",
        "Input",
        "Expected Output",
        "Realized Output",
        "Loss",
        "Loss (%)",
        "Efficiency (%)",
        "Major Concern",
      ].join(",")
    );

    const overallEfficiency =
      (cumulativeOverallData.output / cumulativeOverallData.input) * 100 || 0;
    const overallLossPercent =
      ((cumulativeOverallData.input - cumulativeOverallData.output) /
        cumulativeOverallData.input) *
        100 || 0;
    csvRows.push(
      [
        `Overall Efficiency (${startDate} to ${endDate})`,
        cumulativeOverallData.input,
        cumulativeOverallData.expected,
        cumulativeOverallData.output,
        cumulativeOverallData.loss,
        `${overallLossPercent.toFixed(2)}%`,
        `${overallEfficiency.toFixed(2)}%`,
        lowestEfficiencyStage,
      ].join(",")
    );

    stages.forEach((stage) => {
      if (stage.value !== "overallEfficiency" && stage.value !== "all") {
        const stageData =
          cumulativeStageData[stage.value.replace("Input", "")] || {};
        const stageEfficiency =
          (stageData.realized / stageData.input) * 100 || 0;
        const stageLossPercent = (stageData.loss / stageData.input) * 100 || 0;

        csvRows.push(
          [
            stage.label,
            stageData.input || 0,
            stageData.expected || 0,
            stageData.realized || 0,
            stageData.loss || 0,
            `${stageLossPercent.toFixed(2)}%`,
            `${stageEfficiency.toFixed(2)}%`,
            "",
          ].join(",")
        );
      }
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "Cashew_Nut_Processing_Data.csv");
    a.click();
  };

  const handleDateChange = (field, value) => {
    setAutoFetch(false);
    if (field === "start") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  return (
    <div className="flex ml-36 h-screen">
      {/* Main Content */}
      <div className="flex-1 p-8" style={{ maxWidth: "70%" }}>
        <div className="flex justify-between items-start mb-4">
          <div>
            <h1 className="text-2xl font-bold">
              Cashew Nut Processing Insights
            </h1>
            <p>
              Detailed trends and data insights into the cashew nut processing
              stages.
            </p>
          </div>
        </div>

        {/* Date Pickers */}
        <div className="flex justify-center space-x-4 mb-8">
          <div className="flex items-center space-x-2">
            <label htmlFor="from-date" className="font-medium">
              From
            </label>
            <input
              id="from-date"
              type="date"
              value={startDate}
              onChange={(e) => handleDateChange("start", e.target.value)}
              max={today}
              className="border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div className="flex items-center space-x-2">
            <label htmlFor="to-date" className="font-medium">
              To
            </label>
            <input
              id="to-date"
              type="date"
              value={endDate}
              onChange={(e) => handleDateChange("end", e.target.value)}
              max={today}
              className="border border-gray-300 p-2 rounded-md"
            />
          </div>
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            onClick={() => {
              setAutoFetch(true);
              handleFetchData();
            }}
            disabled={loading || !startDate || !endDate}
          >
            {loading ? "Loading..." : "GO"}
          </button>
        </div>

        {/* Insights Graph */}
        {processDataList.length > 0 && stagesDataList.length > 0 ? (
          <InsightsGraph
            overallEfficiencyData={processDataList.map((processData) =>
              calculateEfficiency(
                processData?.boilingSteamingInput || 0,
                processData?.packagingOutput || 0,
                10
              )
            )}
            lossData={processDataList.map((processData) =>
              calculateLossPercentage(
                processData?.boilingSteamingInput || 0,
                processData?.packagingOutput || 0
              )
            )}
            stagesDataList={stagesDataList}
            processDataList={processDataList}
            cumulativeOverallData={cumulativeOverallData}
            cumulativeStageData={cumulativeStageData}
            hasData={!!startDate && !!endDate}
            processDates={processDataList.map((data) => data.date)}
            lowestEfficiencyStage={lowestEfficiencyStage}
          />
        ) : (
          <p>
            {error ||
              'Please select a valid date range and click "GO" to view data.'}
          </p>
        )}

        {loading && <p>Loading data...</p>}
        {error && <p>{error}</p>}
      </div>

      {/* Icons */}
      <div className="flex flex-col items-center justify-start p-4 space-y-4">
        <img
          src={PdfIcon}
          alt="PDF Export"
          className="w-14 h-14 cursor-pointer"
          onClick={handleExportPDF}
        />
        <img
          src={MailIcon}
          alt="Send Email"
          className="w-14 h-14 cursor-pointer"
          onClick={handleSendEmail}
        />
        <img
          src={ExportIcon}
          alt="Export Data"
          className="w-14 h-14 cursor-pointer"
          onClick={handleExportData}
        />
      </div>

      {/* Sidebar */}
      <div className="fixed right-0 top-[64px] bg-gray-50 w-[15%] h-[calc(100vh-64px)] p-4 text-sm leading-tight overflow-y-auto z-50 shadow-lg">
        <h2 className="text-xl font-bold mb-4">Insights Details</h2>
        <p>Insights (Support bar):</p>
        <ul className="list-disc ml-4">
          <li>
            The default Insights duration is set to the previous one Month.
          </li>
        </ul>
        <p>Charts:</p>
        <ul className="list-disc ml-4">
          <li>Blue bar indicates the overall processing efficiency.</li>
          <li>
            The dotted grey line indicates the overall efficiency in a
            particular stage.
          </li>
          <li>
            Green bar shows your production in the selected positive and safe
            number.
          </li>
          <li>
            Red bar indicates that your production for the day is less than the
            overall and might need improvement.
          </li>
          <li>
            The percentage indicates the efficiency of the process with
            input-output comparison.
          </li>
        </ul>
        <p>Date:</p>
        <ul className="list-disc ml-4">
          <li>Select the dates from start to end.</li>
          <li>
            You can view insights for a single day by selecting the same date in
            both fields.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CashewInsights;
