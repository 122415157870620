import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExportIcon from "../images/Export button.png";
import MailIcon from "../images/Mail icon.png";
import PdfIcon from "../images/PDF icon.png";
import BarGraph from "./BarGraph";

// Define stages for cashew processing
const stages = [
  { value: "all", label: "All Stages" },
  { value: "overallEfficiency", label: "Overall Efficiency" },
  { value: "boilingSteamingInput", label: "Boiling/Steaming" },
  { value: "gradingInput", label: "Grading" },
  { value: "primaryShellingInput", label: "Primary Shelling" },
  { value: "secondaryShellingInput", label: "Secondary Shelling" },
  { value: "coolingInput", label: "Cooling" },
  { value: "bormaDryingInput", label: "Borma Drying" },
  { value: "peelingInput", label: "Peeling" },
  { value: "sweatingInput", label: "Sweating" },
  { value: "sortingInput", label: "Sorting" },
  { value: "packagingInput", label: "Packaging" },
];

// Helper function to calculate efficiency and loss percentages
const calculateEfficiency = (input, output, lossThreshold) => {
  const safeInput = input || 0;
  const safeOutput = output || 0;
  const safeLossThreshold = lossThreshold || 0;

  const total = safeInput;
  const realized = safeOutput;
  const expected = total * (1 - safeLossThreshold / 100);

  return { total, realized, expected };
};

// Render function to display efficiency data for a given stage
const renderEfficiencyData = (stageLabel, input, output, lossThreshold) => {
  const { total, realized, expected } = calculateEfficiency(
    input,
    output,
    lossThreshold
  );
  const loss = total - realized;
  const lossPercent = total ? (loss / total) * 100 : 0;
  const efficiency = total ? (realized / total) * 100 : 0;
  const expectedPercent = total ? (expected / total) * 100 : 0;

  return (
    <div key={stageLabel} className="mt-4 p-4 border rounded shadow-md">
      <h3 className="text-lg font-bold">
        {stageLabel.replace(/([A-Z])/g, " $1").trim()} - {efficiency.toFixed(2)}
        %
      </h3>
      <p>Input: {total.toFixed(2)} Kg</p>
      <p>Expected Output: {expected.toFixed(2)} Kg</p>
      <p>Realized Output: {realized.toFixed(2)} Kg</p>
      <p>
        Loss: {loss.toFixed(2)} Kg ({Math.abs(realized - expected).toFixed(2)}{" "}
        Kg {realized > expected ? "better" : "less"} than {expected.toFixed(2)}{" "}
        Kg normal)
      </p>
      <p>
        Loss (%): {lossPercent.toFixed(2)} % (
        {Math.abs(efficiency - expectedPercent).toFixed(2)} %{" "}
        {efficiency > expectedPercent ? "better" : "less"} than{" "}
        {expectedPercent.toFixed(2)} % normal)
      </p>
      <p>
        Efficiency: {efficiency.toFixed(2)} % (
        {Math.abs(efficiency - expectedPercent).toFixed(2)} %{" "}
        {efficiency > expectedPercent ? "better" : "less"} than{" "}
        {expectedPercent.toFixed(2)} % normal)
      </p>
    </div>
  );
};

const CashewNutDashboard = () => {
  const [processData, setProcessData] = useState({});
  const [stagesData, setStagesData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [viewType, setViewType] = useState("Charts");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const navigate = useNavigate();

  // Default values for each processing stage
  const defaultStagesData = {
    boilingSteaming: 0,
    grading: 0,
    cutting: 0,
    primaryShelling: 0,
    secondaryShelling: 0,
    bormaDrying: 0,
    cooling: 0,
    peeling: 0,
    sweating: 0,
    sorting: 0,
    packaging: 0,
  };

  const defaultProcessData = {
    boilingSteamingInput: 0,
    packagingOutput: 0,
    boilingSteamingOutput: 0,
  };

  // Retrieve token and sleekId from localStorage
  const getToken = () => localStorage.getItem("token");
  const getSleekId = () => localStorage.getItem("sleekId");

  // Format date to YYYY-MM-DD
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  // Fetch the latest available date with data when component mounts
  useEffect(() => {
    const fetchLatestDateWithData = async () => {
      const sleekId = getSleekId();
      const token = getToken();

      if (!sleekId || !token) {
        setError("Sleek ID or token not found. Please log in again.");
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${sleekId}/processing/latest-available-date`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch the latest available date.");
        }

        const latestAvailableDate = await response.json();
        setSelectedDate(formatDate(latestAvailableDate));
        setIsFetched(true);
      } catch (error) {
        console.error("Error fetching latest available date:", error.message);
        setError("Failed to fetch the latest available date.");
      }
    };

    fetchLatestDateWithData();
  }, []);

  // Fetch data for the selected date and update state
  useEffect(() => {
    if (selectedDate && isFetched) {
      const fetchData = async () => {
        const sleekId = getSleekId();
        const token = getToken();

        if (!sleekId || !token) {
          setError("Sleek ID or token not found. Please log in again.");
          return;
        }

        setLoading(true);

        try {
          const processingResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/${sleekId}/processing?date=${selectedDate}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const stagesResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!processingResponse.ok || !stagesResponse.ok) {
            const errorMessage = `Processing API status: ${
              processingResponse.status || "Unknown"
            }, Stages API status: ${stagesResponse.status || "Unknown"}`;
            throw new Error(errorMessage);
          }

          const processingData = await processingResponse.json();
          const stagesData = await stagesResponse.json();

          setProcessData(processingData.length > 0 ? processingData[0] : {});
          setStagesData(stagesData);
          setError(null);
        } catch (error) {
          console.error("Error fetching data:", error.message);
          setError(`Failed to fetch data: ${error.message}`);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedDate, isFetched]);

  // Handle date change in input
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setIsFetched(false);
  };

  // Handle view type changes
  const handleViewChange = (event) => {
    setViewType(event.target.value);
  };

  // Trigger data fetching for the selected date
  const handleFetchAndView = () => {
    if (selectedDate) {
      setIsFetched(true);
    }
  };

  // Disable actions if data is not yet fetched
  const isActionDisabled = !isFetched;

  // Export data as PDF, formatted with efficiency and loss details
  const handleExportPDF = useCallback(() => {
    if (isActionDisabled) {
      alert(
        "Please select a date and click 'Go' to display the data before exporting."
      );
      return;
    }

    const doc = new jsPDF();
    doc.setFontSize(28);
    const title = "Cashew Nut Processing Dashboard";
    const pageWidth = doc.internal.pageSize.getWidth();
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, 20);

    doc.setFontSize(14);
    const subtitle = `Efficiency Data for ${selectedDate}`;
    const subtitleWidth = doc.getTextWidth(subtitle);
    doc.text(subtitle, (pageWidth - subtitleWidth) / 2, 30);

    const tableColumn = [
      "Stage",
      "Total (Kg)",
      "Expected (Kg)",
      "Realized (Kg)",
      "Efficiency (%)",
      "Loss (%)",
    ];
    const tableRows = [];

    const formatPercentage = (value) =>
      isNaN(value) ? "0.00%" : value.toFixed(2) + "%";

    const calculateExpected = (input, lossThreshold) => {
      return input * (1 - lossThreshold / 100);
    };

    const overallInput = processData?.boilingSteamingInput || 0;
    const overallOutput = processData?.packagingOutput || 0;
    const overallLossThreshold = Object.values(stagesData).reduce(
      (acc, val) => acc + val,
      0
    );
    const overallExpected = calculateExpected(
      overallInput,
      overallLossThreshold
    );
    const overallEfficiency = overallInput
      ? (overallOutput / overallInput) * 100
      : 0;
    const overallLossPercent = overallInput
      ? ((overallInput - overallOutput) / overallInput) * 100
      : 0;

    tableRows.push([
      "Overall Efficiency",
      overallInput.toFixed(2),
      overallExpected.toFixed(2),
      overallOutput.toFixed(2),
      formatPercentage(overallEfficiency),
      formatPercentage(overallLossPercent),
    ]);

    Object.keys(defaultStagesData).forEach((stage) => {
      const stageLabel = stage.replace(/([A-Z])/g, " $1").trim();
      const input = processData?.[`${stage}Input`] || 0;
      const output = processData?.[`${stage}Output`] || 0;
      const lossThreshold = stagesData?.[stage] || 0;
      const expected = calculateExpected(input, lossThreshold);
      const efficiency = input ? (output / input) * 100 : 0;
      const lossPercent = input ? ((input - output) / input) * 100 : 0;

      tableRows.push([
        stageLabel,
        input.toFixed(2),
        expected.toFixed(2),
        output.toFixed(2),
        formatPercentage(efficiency),
        formatPercentage(lossPercent),
      ]);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 40,
      theme: "striped",
      margin: { top: 50 },
    });

    doc.save(`Cashew_Nut_Processing_Dashboard_${selectedDate}.pdf`);
  }, [
    processData,
    selectedDate,
    stagesData,
    isActionDisabled,
    defaultStagesData,
  ]);

  // Export data as CSV, ensuring it matches the PDF structure
  const handleExportData = () => {
    if (isActionDisabled) {
      alert(
        "Please select a date and click 'Go' to display the data before exporting."
      );
      return;
    }

    const csvRows = [];
    const headers = [
      "Stage",
      "Total (Kg)",
      "Expected (Kg)",
      "Realized (Kg)",
      "Efficiency (%)",
      "Loss (%)",
    ];
    csvRows.push(headers.join(","));

    const calculateExpected = (input, lossThreshold) => {
      return input * (1 - lossThreshold / 100);
    };

    const formatPercentage = (value) =>
      isNaN(value) ? "0.00%" : value.toFixed(2) + "%";

    const overallInput = processData?.boilingSteamingInput || 0;
    const overallOutput = processData?.packagingOutput || 0;
    const overallLossThreshold = Object.values(stagesData).reduce(
      (acc, val) => acc + val,
      0
    );
    const overallExpected = calculateExpected(
      overallInput,
      overallLossThreshold
    );
    const overallEfficiency = overallInput
      ? (overallOutput / overallInput) * 100
      : 0;
    const overallLossPercent = overallInput
      ? ((overallInput - overallOutput) / overallInput) * 100
      : 0;

    csvRows.push(
      `Overall Efficiency,${overallInput.toFixed(2)},${overallExpected.toFixed(
        2
      )},${overallOutput.toFixed(2)},${formatPercentage(
        overallEfficiency
      )},${formatPercentage(overallLossPercent)}`
    );

    Object.keys(defaultStagesData).forEach((stage) => {
      const stageLabel = stage.replace(/([A-Z])/g, " $1").trim();
      const input = processData?.[`${stage}Input`] || 0;
      const output = processData?.[`${stage}Output`] || 0;
      const lossThreshold = stagesData?.[stage] || 0;
      const expected = calculateExpected(input, lossThreshold);
      const efficiency = input ? (output / input) * 100 : 0;
      const lossPercent = input ? ((input - output) / input) * 100 : 0;

      csvRows.push(
        `${stageLabel},${input.toFixed(2)},${expected.toFixed(
          2
        )},${output.toFixed(2)},${formatPercentage(
          efficiency
        )},${formatPercentage(lossPercent)}`
      );
    });

    // Generate and download the CSV file
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `Cashew_Nut_Data_${selectedDate}.csv`);
    document.body.appendChild(a); // Append the anchor element to the body
    a.click(); // Programmatically click the anchor to trigger download
    document.body.removeChild(a); // Remove the anchor after download
    URL.revokeObjectURL(url); // Clean up the URL object
  };

  // Function to send data report via email
  const handleSendEmail = async (event) => {
    event.preventDefault();

    try {
      const token = getToken();
      const sleekId = getSleekId();

      if (!token || !sleekId) {
        setError("Sleek ID or token not found. Please log in again.");
        return;
      }

      const generateEmailBody = () => {
        let emailBody = `Cashew Nut Processing Dashboard - ${selectedDate}\n\n`;
        emailBody += `Overall Efficiency:\n`;

        const overallEfficiencyData = calculateEfficiency(
          processData?.boilingSteamingInput ||
            defaultProcessData.boilingSteamingInput,
          processData?.packagingOutput || defaultProcessData.packagingOutput,
          Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
        );

        emailBody += `- Total: ${overallEfficiencyData.total.toFixed(2)} Kg\n`;
        emailBody += `- Expected: ${overallEfficiencyData.expected.toFixed(
          2
        )} Kg\n`;
        emailBody += `- Realized: ${overallEfficiencyData.realized.toFixed(
          2
        )} Kg\n`;
        emailBody += `- Efficiency: ${(
          (overallEfficiencyData.realized / overallEfficiencyData.total) *
          100
        ).toFixed(2)}%\n\n`;

        Object.keys(defaultStagesData).forEach((stage) => {
          const stageData = calculateEfficiency(
            processData?.[`${stage}Input`] || 0,
            processData?.[`${stage}Output`] || 0,
            stagesData?.[stage] || 0
          );

          emailBody += `${stage.replace(/([A-Z])/g, " $1")}: \n`;
          emailBody += `- Total: ${stageData.total.toFixed(2)} Kg\n`;
          emailBody += `- Expected: ${stageData.expected.toFixed(2)} Kg\n`;
          emailBody += `- Realized: ${stageData.realized.toFixed(2)} Kg\n`;
          emailBody += `- Efficiency: ${(
            (stageData.realized / stageData.total) *
            100
          ).toFixed(2)}%\n\n`;
        });

        return emailBody;
      };

      const emailBody = generateEmailBody();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/emails/send-mail?sleekId=${sleekId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject: "Cashew Nut Processing Dashboard Insights",
            text: emailBody,
          }),
        }
      );

      if (response.ok) {
        setError(null);
        alert("Email sent successfully!");
      } else {
        const errorResponse = await response.json();
        setError(
          `Failed to send email: ${
            errorResponse.message || "Please try again."
          }`
        );
      }
    } catch (error) {
      setError("An unexpected error occurred.");
    }
  };

  return (
    <div className="flex ml-36 h-screen">
      <div className="flex-1 p-8" style={{ maxWidth: "70%" }}>
        <div className="flex justify-between items-start mb-4">
          <div>
            <h1 className="text-2xl font-bold">
              Cashew Nut Processing Efficiency
            </h1>
            <br></br>
            <p>
              <strong>Enhance Processing Efficiency: </strong>Access insights
              into your cashew nut processing operations. Analyze input and
              output quantities along with loss percentages to identify
              opportunities for improvement. Make informed decisions to boost
              productivity and profitability.
            </p>
          </div>
        </div>

        <div className="mb-4 flex items-center space-x-2">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            max={new Date().toISOString().split("T")[0]}
            className="bg-white p-2 rounded border-2 border-black text-black"
          />
          <div className="flex space-x-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="view"
                value="Charts"
                className="mr-2"
                checked={viewType === "Charts"}
                onChange={handleViewChange}
              />
              Charts
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="view"
                value="Numbers"
                className="mr-2"
                checked={viewType === "Numbers"}
                onChange={handleViewChange}
              />
              Numbers
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="view"
                value="Both"
                className="mr-2"
                checked={viewType === "Both"}
                onChange={handleViewChange}
              />
              Both
            </label>
          </div>
          <button
            className="ml-4 bg-gray-800 p-2 rounded hover:bg-gray-700 text-white"
            onClick={handleFetchAndView}
          >
            Go
          </button>
        </div>

        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}

        {/* Conditionally render charts, numbers, or both based on viewType */}
        {!loading && !error && isFetched && viewType === "Charts" && (
          <>
            <BarGraph
              title="Overall Efficiency"
              {...calculateEfficiency(
                processData?.boilingSteamingInput ||
                  defaultProcessData.boilingSteamingInput,
                processData?.packagingOutput ||
                  defaultProcessData.packagingOutput,
                Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
              )}
            />
            {Object.keys(defaultStagesData).map((stage) => (
              <BarGraph
                key={stage}
                title={stage.replace(/([A-Z])/g, " $1")}
                {...calculateEfficiency(
                  processData?.[`${stage}Input`] || 0,
                  processData?.[`${stage}Output`] || 0,
                  stagesData?.[stage] || 0
                )}
              />
            ))}
          </>
        )}

        {!loading && !error && isFetched && viewType === "Numbers" && (
          <div id="dashboard-content">
            {renderEfficiencyData(
              "Overall Efficiency",
              processData?.boilingSteamingInput ||
                defaultProcessData.boilingSteamingInput,
              processData?.packagingOutput ||
                defaultProcessData.packagingOutput,
              Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
            )}
            {Object.keys(defaultStagesData).map((stage) =>
              renderEfficiencyData(
                stage,
                processData?.[`${stage}Input`] || 0,
                processData?.[`${stage}Output`] || 0,
                stagesData?.[stage] || 0
              )
            )}
          </div>
        )}

        {!loading && !error && isFetched && viewType === "Both" && (
          <>
            <BarGraph
              title="Overall Efficiency"
              {...calculateEfficiency(
                processData?.boilingSteamingInput ||
                  defaultProcessData.boilingSteamingInput,
                processData?.packagingOutput ||
                  defaultProcessData.packagingOutput,
                Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
              )}
            />
            {renderEfficiencyData(
              "Overall Efficiency",
              processData?.boilingSteamingInput ||
                defaultProcessData.boilingSteamingInput,
              processData?.packagingOutput ||
                defaultProcessData.packagingOutput,
              Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
            )}
            {Object.keys(defaultStagesData).map((stage) => (
              <React.Fragment key={stage}>
                <BarGraph
                  title={stage.replace(/([A-Z])/g, " $1")}
                  {...calculateEfficiency(
                    processData?.[`${stage}Input`] || 0,
                    processData?.[`${stage}Output`] || 0,
                    stagesData?.[stage] || 0
                  )}
                />
                {renderEfficiencyData(
                  stage,
                  processData?.[`${stage}Input`] || 0,
                  processData?.[`${stage}Output`] || 0,
                  stagesData?.[stage] || 0
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>

      {/* Icons to trigger PDF export, email sending, and CSV export */}
      <div className="flex flex-col items-center justify-start p-4 space-y-4">
        <img
          src={PdfIcon}
          alt="PDF Export"
          className="w-14 h-14 cursor-pointer"
          onClick={handleExportPDF}
        />
        <img
          src={MailIcon}
          alt="Send Email"
          className="w-14 h-14 cursor-pointer"
          onClick={handleSendEmail}
        />
        <img
          src={ExportIcon}
          alt="Export Data"
          className="w-14 h-14 cursor-pointer"
          onClick={handleExportData}
        />
      </div>

      {/* Instructional side panel */}
      <div className="fixed right-0 bg-gray-50 w-full md:w-1/4 lg:w-[15%] h-full p-2 text-sm leading-tight">
        <h2 className="text-xl font-bold mb-4">Dashboard support bar</h2>
        <p>
          <strong>Default Workbench View: </strong>
          The dashboard shows the latest day's processing stats by default, with
          options to select a different date.
        </p>
        <br />
        <p>
          <strong>Data Entry Prompt: </strong>
          If no data exists for a selected day, the dashboard prompts you to
          input it via the Data menu.
        </p>
        <br />
        <p>
          <strong>Input, Output & Loss: </strong>
          Displays input-output quantities for each stage, with losses shown in
          both absolute terms and percentages.
        </p>
        <br />
        <p>
          <strong>Loss Alerts: </strong>
          Clear indications appear when losses exceed thresholds—green for
          expected values, red for losses, and grey for total input.
        </p>
        <br />
        <p>
          <strong>Export Options: </strong>
          Download, email, or export the data as a PDF or CSV file for further
          analysis.
        </p>
      </div>
    </div>
  );
};

export default CashewNutDashboard;
