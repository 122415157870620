import React from "react";
import { useNavigate } from "react-router-dom";
import Details from "./dashboard.jpg";
import myImage from "./gallery.jpg";
import cashew from "./insights.jpg";

const SettingsHome = ({ handleProfile }) => {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <div
      className="flex-1 mt-0"
      style={{
        marginLeft: "4.2cm", // Shift page content 4.2cm to the right
        height: "calc(100vh - 5rem)", // Full-screen fit without scrolling
      }}
    >
      <div className="flex-1">
        <h1 className="text-xl font-bold mb-3">
          This is where you prepare Sleek for you!
        </h1>
        <p className="mb-4 text-sm">
          Welcome to the Sleek Setup! This is your hub for customizing the app
          to fit your cashew processing needs. Adjust settings to create a
          personalized experience, optimize workflows, and enhance efficiency.
          Whether you're setting up for the first time or refining existing
          configurations, Sleek allows you to tailor the app to streamline your
          operations. Get started to make Sleek work perfectly for you!
        </p>

        {/* Profile Section */}
        <div className="flex items-center mb-3 bg-white p-3 rounded">
          <img
            src={myImage}
            alt="Profile"
            className="w-16 h-16 mr-3 rounded-md object-cover" // Reduced image size
          />
          <div className="flex-1">
            <h2 className="text-lg font-semibold mb-1 mx-2">
              Let your profile reflect you
            </h2>
            <p className="mb-3 mx-2 text-sm">
              Sleek offers users the ability to tailor their profiles to match
              personal preferences or company-specific requirements. You can
              update contact information, set preferences, and even add profile
              images, making Sleek feel unique to you. This feature enhances the
              app's alignment with individual workflows and ensures a customized
              experience that reflects your branding.
            </p>
            <button
              onClick={() => navigate("/workspace/settings/profile")}
              className="bg-black text-white px-3 py-1 rounded mx-2 hover:scale-105 transition-transform duration-200"
            >
              Go to Profile Settings
            </button>
          </div>
        </div>

        {/* Processing Workflows Section */}
        <div className="flex items-center mb-3 bg-white p-3 rounded">
          <img
            src={Details}
            alt="Processing"
            className="w-16 h-16 mr-3 rounded-md object-cover" // Reduced image size
          />
          <div className="flex-1">
            <h2 className="text-lg font-semibold mb-1 mx-2">
              Design Processing Workflows
            </h2>
            <p className="mb-3 mx-2 text-sm">
              With Sleek, users can create and modify cashew processing
              workflows to suit their exact operational needs. From raw nut
              reception to final packaging, each stage can be customized to
              match real-life processes. This ensures seamless task flow and
              improved operational efficiency, minimizing gaps in production.
            </p>
            <button
              onClick={() => navigate("/workspace/settings/commodities")}
              className="bg-black text-white px-3 py-1 rounded mx-2 hover:scale-105 transition-transform duration-200"
            >
              Go to Commodities Settings
            </button>
          </div>
        </div>

        {/* Audit Section */}
        <div className="flex items-center bg-white p-3 rounded">
          <img
            src={cashew}
            alt="Efficiency"
            className="w-16 h-16 mr-3 rounded-md object-cover" // Reduced image size
          />
          <div className="flex-1">
            <h2 className="text-lg font-semibold mb-1 mx-2">
              Audit the changes
            </h2>
            <p className="mb-3 mx-2 text-sm">
              Sleek enables users to audit and track all changes made within the
              app, offering a high level of traceability. Whether it’s workflow
              adjustments or settings updates, all modifications are recorded
              and can be reviewed later. This ensures that operations remain
              accurate, efficient, and fully transparent for further
              optimization or quality control.
            </p>
            <button
              className="bg-black text-white px-3 py-1 rounded mx-2 hover:scale-105 transition-transform duration-200"
              onClick={() => navigate("/workspace/settings/history")}
            >
              Go to History
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsHome;
