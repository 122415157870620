// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );
// import React from "react";
// import { createRoot } from "react-dom/client";
// import App from "./App";
 import "./index.css"
// const container = document.getElementById("root");
// const root = createRoot(container); // createRoot is used instead of ReactDOM.render

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
