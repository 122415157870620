import React from "react";
import { Link, useLocation } from "react-router-dom";
import cashewnuts from "../images/Cashewnut 1.png";
import krissaco from "../images/krissaco-mascot.png";
import settings from "../images/Setting icon 2.png";

const NavigationBar = ({ activatedCommodities }) => {
  const location = useLocation();

  // Check if Cashew Nut is activated from the parent component (passed as props)
  const isCashewNutActivated =
    Array.isArray(activatedCommodities) &&
    activatedCommodities.includes("Cashew Nut");

  return (
    <div className="bg-green-500 p-4 fixed flex top-0 left-0 flex-col justify-between items-center h-full w-20">
      <div>
        <Link to="/workspace" className="mr-4">
          <img src={krissaco} alt="Sleek Logo" className="h-25 width-auto" />
          <div className="text-xs text-white text-center">
            Krissaco Sleek V1
          </div>
        </Link>
      </div>

      {/* Cashew Nut button shown only if activated */}
      {/** {isCashewNutActivated && (*/}
        <div>
          <Link to="/workspace/cashew" className="mr-4">
            <img src={cashewnuts} alt="Cashew Nut" className="w-24 h-auto" />
            <div className="text-xs text-white text-center">Cashewnut</div>
          </Link>
        </div>
      {/**}) */}

      <div className="mt-auto">
        <Link to="settings" className="mr-4">
          <img src={settings} alt="Settings" className="w-24 h-auto" />
          <div className="text-xs text-white text-center">Settings</div>
        </Link>
      </div>
    </div>
  );
};

export default NavigationBar;
