import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ReportTable from "./ReportTable";

// Import button icons
import ExportIcon from "../images/Export button.png";
import MailIcon from "../images/Mail icon.png";
import PdfIcon from "../images/PDF icon.png";

// Utility function to format date to YYYY-MM-DD
const formatDate = (date) => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Get the date 30 days ago
const getLastMonthDate = () => {
  const today = new Date();
  const lastMonth = new Date(today.setDate(today.getDate() - 30));
  return formatDate(lastMonth);
};

const CashewReports = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(getLastMonthDate());
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [selectedStage, setSelectedStage] = useState("all");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataFetched, setDataFetched] = useState(false); // Track data fetching state

  // Updated stage order to match the backend structure
  const stages = [
    { value: "all", label: "All Stages" },
    { value: "overallEfficiency", label: "Overall Efficiency" },
    { value: "boilingSteamingInput", label: "Boiling/Steaming" },
    { value: "gradingInput", label: "Grading" },
    { value: "cuttingInput", label: "Cutting" }, // New Cutting stage
    { value: "primaryShellingInput", label: "Primary Shelling" },
    { value: "secondaryShellingInput", label: "Secondary Shelling" },
    { value: "bormaDryingInput", label: "Borma Drying" },
    { value: "coolingInput", label: "Cooling" }, // Updated "Cooling" stage
    { value: "peelingInput", label: "Peeling" },
    { value: "sweatingInput", label: "Sweating" },
    { value: "sortingInput", label: "Sorting" },
    { value: "packagingInput", label: "Packaging" },
  ];

  // Fetch data on component mount for last 30 days
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (!startDate || !endDate) {
      setError("Start date or end date is missing!");
      return;
    }

    try {
      setLoading(true);
      setError("");
      setDataFetched(false); // Reset data fetch state

      // Retrieve the JWT token and sleekId from localStorage
      const token = localStorage.getItem("token");
      const sleekId = localStorage.getItem("sleekId");

      if (!token || !sleekId) {
        setError("Sleek ID or token not found. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/${sleekId}/processing/reports`; // Backend API

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass JWT in Authorization header
        },
        params: {
          startDate,
          endDate,
        },
      });

      if (response.data.length > 0) {
        setData(response.data);
        setDataFetched(true); // Set data fetched successfully
      } else {
        setError("No data found for the selected date range.");
        setData([]);
      }
    } catch (error) {
      setError(
        `Failed to fetch data: ${
          error.response?.status === 404
            ? "API endpoint not found"
            : error.message
        }`
      );
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchData = () => {
    setData([]);
    fetchData();
  };

  const processStageData = (stageInput, stageOutput) => {
    let cumulativeInput = 0;
    let cumulativeOutput = 0;
    let cumulativeLoss = 0;

    return data.map((entry, index) => {
      const stageInputValue = entry[stageInput] ?? 0;
      const stageOutputValue = entry[stageOutput] ?? 0;

      const loss = stageInputValue - stageOutputValue;
      cumulativeInput += stageInputValue;
      cumulativeOutput += stageOutputValue;
      cumulativeLoss += loss;

      const averageLoss = cumulativeLoss / (index + 1);

      return {
        serialNumber: index + 1,
        date: new Date(entry.date).toLocaleDateString("en-GB"),
        input: stageInputValue,
        output: stageOutputValue,
        loss: loss,
        totalInput: cumulativeInput,
        totalLoss: cumulativeLoss,
        averageLoss: averageLoss,
        totalOutput: cumulativeOutput,
      };
    });
  };

  const processOverallEfficiencyData = () => {
    let cumulativeInput = 0;
    let cumulativeOutput = 0;
    let cumulativeLoss = 0;

    return data.map((entry, index) => {
      const totalInput = entry.boilingSteamingInput ?? 0;
      const totalOutput = entry.packagingOutput ?? 0;
      const loss = totalInput - totalOutput;

      cumulativeInput += totalInput;
      cumulativeOutput += totalOutput;
      cumulativeLoss += loss;

      const averageLoss = cumulativeLoss / (index + 1);

      return {
        serialNumber: index + 1,
        date: new Date(entry.date).toLocaleDateString("en-GB"),
        input: totalInput,
        output: totalOutput,
        loss: loss,
        totalInput: cumulativeInput,
        totalLoss: cumulativeLoss,
        averageLoss: averageLoss,
        totalOutput: cumulativeOutput,
      };
    });
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    if (selectedStage === "overallEfficiency") {
      doc.text("Cashew Nut Processing Report", 20, 10);
      doc.autoTable({
        head: [
          [
            "#",
            "Date",
            "Input",
            "Output",
            "Loss",
            "Total Input",
            "Total Loss",
            "Average Loss",
            "Total Output",
          ],
        ],
        body: processOverallEfficiencyData().map((item) => [
          item.serialNumber,
          item.date,
          item.input,
          item.output,
          item.loss,
          item.totalInput,
          item.totalLoss,
          item.averageLoss.toFixed(2),
          item.totalOutput,
        ]),
        startY: 20,
      });
    } else if (selectedStage === "all") {
      doc.text("Cashew Nut Processing Report", 20, 10);
      doc.autoTable({
        head: [
          [
            "#",
            "Date",
            "Input",
            "Output",
            "Loss",
            "Total Input",
            "Total Loss",
            "Average Loss",
            "Total Output",
          ],
        ],
        body: processOverallEfficiencyData().map((item) => [
          item.serialNumber,
          item.date,
          item.input,
          item.output,
          item.loss,
          item.totalInput,
          item.totalLoss,
          item.averageLoss.toFixed(2),
          item.totalOutput,
        ]),
        startY: 20,
      });

      stages.slice(2).forEach((stage) => {
        doc.addPage();
        doc.text(`${stage.label} Efficiency`, 20, 10);

        const stageData = processStageData(
          stage.value,
          stage.value.replace("Input", "Output")
        );

        doc.autoTable({
          head: [
            [
              "#",
              "Date",
              "Input",
              "Output",
              "Loss",
              "Total Input",
              "Total Loss",
              "Average Loss",
              "Total Output",
            ],
          ],
          body: stageData.map((item) => [
            item.serialNumber,
            item.date,
            item.input,
            item.output,
            item.loss,
            item.totalInput,
            item.totalLoss,
            item.averageLoss.toFixed(2),
            item.totalOutput,
          ]),
          startY: 20,
        });
      });
    } else {
      const stageLabel = stages.find(
        (stage) => stage.value === selectedStage
      )?.label;
      doc.text(`${stageLabel} Efficiency`, 20, 10);

      const stageData = processStageData(
        selectedStage,
        selectedStage.replace("Input", "Output")
      );

      doc.autoTable({
        head: [
          [
            "#",
            "Date",
            "Input",
            "Output",
            "Loss",
            "Total Input",
            "Total Loss",
            "Average Loss",
            "Total Output",
          ],
        ],
        body: stageData.map((item) => [
          item.serialNumber,
          item.date,
          item.input,
          item.output,
          item.loss,
          item.totalInput,
          item.totalLoss,
          item.averageLoss.toFixed(2),
          item.totalOutput,
        ]),
        startY: 20,
      });
    }

    doc.save("Cashew_Processing_Report.pdf");
  };

  const exportToCSV = () => {
    let csvData;
    if (selectedStage === "overallEfficiency") {
      csvData = processOverallEfficiencyData().map((item) => ({
        "#": item.serialNumber,
        Date: item.date,
        Input: item.input,
        Output: item.output,
        Loss: item.loss,
        "Total Input": item.totalInput,
        "Total Loss": item.totalLoss,
        "Average Loss": item.averageLoss.toFixed(2),
        "Total Output": item.totalOutput,
        stage: "Overall Efficiency",
      }));
    } else if (selectedStage === "all") {
      csvData = processOverallEfficiencyData().map((item) => ({
        "#": item.serialNumber,
        Date: item.date,
        Input: item.input,
        Output: item.output,
        Loss: item.loss,
        "Total Input": item.totalInput,
        "Total Loss": item.totalLoss,
        "Average Loss": item.averageLoss.toFixed(2),
        "Total Output": item.totalOutput,
        stage: "Overall Efficiency",
      }));

      stages.slice(2).forEach((stage) => {
        const stageData = processStageData(
          stage.value,
          stage.value.replace("Input", "Output")
        ).map((item) => ({
          "#": item.serialNumber,
          Date: item.date,
          Input: item.input,
          Output: item.output,
          Loss: item.loss,
          "Total Input": item.totalInput,
          "Total Loss": item.totalLoss,
          "Average Loss": item.averageLoss.toFixed(2),
          "Total Output": item.totalOutput,
          stage: stage.label,
        }));
        csvData = [...csvData, ...stageData];
      });
    } else {
      const stageData = processStageData(
        selectedStage,
        selectedStage.replace("Input", "Output")
      ).map((item) => ({
        "#": item.serialNumber,
        Date: item.date,
        Input: item.input,
        Output: item.output,
        Loss: item.loss,
        "Total Input": item.totalInput,
        "Total Loss": item.totalLoss,
        "Average Loss": item.averageLoss.toFixed(2),
        "Total Output": item.totalOutput,
        stage: stages.find((stage) => stage.value === selectedStage)?.label,
      }));
      csvData = stageData;
    }
    return csvData;
  };

  const csvData = [
    [
      "#",
      "Date",
      "Stage",
      "Input",
      "Output",
      "Loss",
      "Total Input",
      "Total Loss",
      "Average Loss",
      "Total Output",
    ],
    ...processOverallEfficiencyData().map((item) => [
      item.serialNumber,
      item.date,
      "Overall Efficiency",
      item.input,
      item.output,
      item.loss,
      item.totalInput,
      item.totalLoss,
      item.averageLoss.toFixed(2),
      item.totalOutput,
    ]),
    ...stages
      .slice(2)
      .flatMap((stage) =>
        processStageData(
          stage.value,
          stage.value.replace("Input", "Output")
        ).map((item) => [
          item.serialNumber,
          item.date,
          stage.label,
          item.input,
          item.output,
          item.loss,
          item.totalInput,
          item.totalLoss,
          item.averageLoss.toFixed(2),
          item.totalOutput,
        ])
      ),
  ];

  const today = formatDate(new Date());

  const handleSendEmail = async () => {
    try {
      const token = localStorage.getItem("token");
      const sleekId = localStorage.getItem("sleekId");

      if (!token || !sleekId) {
        setError("Sleek ID or token not found. Please log in again.");
        return;
      }

      const generateEmailBody = () => {
        let emailBody = `Cashew Nut Processing Report - From ${startDate} To ${endDate}\n\n`;

        emailBody += "Overall Efficiency:\n";
        processOverallEfficiencyData().forEach((item) => {
          emailBody += `Date: ${item.date}, Input: ${item.input}, Output: ${
            item.output
          }, Loss: ${item.loss}, Total Input: ${item.totalInput}, Total Loss: ${
            item.totalLoss
          }, Average Loss: ${item.averageLoss.toFixed(2)}, Total Output: ${
            item.totalOutput
          }\n`;
        });

        if (selectedStage === "all") {
          stages.slice(2).forEach((stage) => {
            emailBody += `\n${stage.label} Efficiency:\n`;
            const stageData = processStageData(
              stage.value,
              stage.value.replace("Input", "Output")
            );
            stageData.forEach((item) => {
              emailBody += `Date: ${item.date}, Input: ${item.input}, Output: ${
                item.output
              }, Loss: ${item.loss}, Total Input: ${
                item.totalInput
              }, Total Loss: ${
                item.totalLoss
              }, Average Loss: ${item.averageLoss.toFixed(2)}, Total Output: ${
                item.totalOutput
              }\n`;
            });
          });
        } else {
          const stageLabel = stages.find(
            (s) => s.value === selectedStage
          )?.label;
          emailBody += `\n${stageLabel} Efficiency:\n`;
          const stageData = processStageData(
            selectedStage,
            selectedStage.replace("Input", "Output")
          );
          stageData.forEach((item) => {
            emailBody += `Date: ${item.date}, Input: ${item.input}, Output: ${
              item.output
            }, Loss: ${item.loss}, Total Input: ${
              item.totalInput
            }, Total Loss: ${
              item.totalLoss
            }, Average Loss: ${item.averageLoss.toFixed(2)}, Total Output: ${
              item.totalOutput
            }\n`;
          });
        }

        return emailBody;
      };

      const emailBody = generateEmailBody();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/emails/send-mail?sleekId=${sleekId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject: "Cashew Nut Processing Dashboard Insights",
            text: emailBody,
          }),
        }
      );

      if (response.ok) {
        setError(null);
        alert("Email sent successfully!");
      } else {
        const errorResponse = await response.json();
        setError(
          `Failed to send email: ${
            errorResponse.message || "Please try again."
          }`
        );
      }
    } catch (error) {
      setError("An unexpected error occurred.");
    }
  };

  return (
    <div className="flex ml-36 h-screen">
      <div className="flex-1 p-8" style={{ maxWidth: "70%" }}>
        <div className="flex justify-between items-start mb-4">
          <div>
            <h1 className="text-3xl font-bold mb-4">
              Cashew Nut Processing Report
            </h1>
            <p className="text-gray-500 mb-6">
              Access a comprehensive report of your processed data, allowing you
              to select specific processing stages or view all stages
              collectively. The report can be customized to display data ranging
              from days to months and even years by selecting your desired start
              and end dates. This flexibility enables detailed analysis and
              informed decision-making.
            </p>
          </div>
        </div>

        {/* Date Pickers */}
        <div className="flex justify-center space-x-4 mb-8">
          <div className="flex items-center space-x-2">
            <label htmlFor="from-date" className="font-medium">
              From
            </label>
            <input
              id="from-date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={today}
              className="border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div className="flex items-center space-x-2">
            <label htmlFor="to-date" className="font-medium">
              To
            </label>
            <input
              id="to-date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              max={today}
              className="border border-gray-300 p-2 rounded-md"
            />
          </div>
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            onClick={handleFetchData}
            disabled={loading}
          >
            {loading ? "Loading..." : "GO"}
          </button>
        </div>

        {/* Error Handling */}
        {error && <div className="text-red-500 text-center mb-4">{error}</div>}

        <div className="w-[80%] h-fit flex gap-3 justify-center">
          {/* Stage Filter Label */}
          <div className="">
            <label className="font-medium">Stages</label>
          </div>

          {/* Stage Filter Dropdown */}
          <div className="flex justify-center mb-6">
            <select
              className="border border-gray-300 p-2 rounded-md"
              value={selectedStage}
              onChange={(e) => setSelectedStage(e.target.value)}
            >
              {stages.map((stage) => (
                <option key={stage.value} value={stage.value}>
                  {stage.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* Render Based on Selected Stage */}
        {selectedStage === "overallEfficiency" ? (
          <ReportTable
            title="Overall Efficiency"
            data={processOverallEfficiencyData()}
          />
        ) : selectedStage === "all" ? (
          <>
            <ReportTable
              title="Overall Efficiency"
              data={processOverallEfficiencyData()}
            />
            {stages.slice(2).map((stage) => (
              <ReportTable
                key={stage.value}
                title={stage.label}
                data={processStageData(
                  stage.value,
                  stage.value.replace("Input", "Output")
                )}
              />
            ))}
          </>
        ) : (
          <ReportTable
            title={stages.find((stage) => stage.value === selectedStage)?.label}
            data={processStageData(
              selectedStage,
              selectedStage.replace("Input", "Output")
            )}
          />
        )}
      </div>

      {/* Right Side Bar */}
      <div className="fixed right-0 bg-gray-50 w-full sm:w-1/2 md:w-1/3 lg:w-[18%] xl:w-[15%] h-full p-2 text-xs sm:text-sm leading-snug border-l border-gray-200 shadow-md">
        <h2 className="text-lg font-extrabold mb-3 text-gray-800 bg-gradient-to-r from-black to-black text-transparent bg-clip-text">
          Report Support Bar
        </h2>

        <p className="mb-2 text-gray-700">
          <strong>Report Overview:</strong> Clicking the Reports item in the
          menu bar presents a detailed analysis of input data in a tabular
          format for the last month by default. You can customize the report
          duration by selecting the preferred start and end dates.
        </p>

        <p className="mb-2 text-gray-700">
          <strong>Date Selection:</strong> Specify the desired start and end
          dates for the report, allowing for tailored insights into the
          processing data.
        </p>

        <p className="mb-2 text-gray-700">
          <strong>Stage Selection:</strong> Choose specific processing stages
          predetermined during the activation of the commodity. An option to
          view all stages together is also available.
        </p>

        <p className="mb-2 text-gray-700">
          <strong>PDF Download:</strong> Print or download the report as a PDF
          by clicking the PDF icon.
        </p>

        <p className="mb-2 text-gray-700">
          <strong>Email Notification:</strong> Send the report to the registered
          email ID with a simple click.
        </p>

        <p className="text-gray-700">
          <strong>Data Export:</strong> Export input data to a CSV file for
          further analysis or record-keeping.
        </p>
      </div>

      {/* PDF, Mail, and Export Icons */}
      <div className="flex flex-col items-center justify-start p-4 space-y-4">
        <img
          src={PdfIcon}
          alt="PDF Export"
          className={`w-14 h-14 cursor-pointer ${
            !dataFetched ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => dataFetched && exportToPDF()}
        />
        <img
          src={MailIcon}
          alt="Send Email"
          className={`w-14 h-14 cursor-pointer ${
            !dataFetched ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => dataFetched && handleSendEmail()}
        />
        <CSVLink data={exportToCSV()} filename={"cashew_report.csv"}>
          <img
            src={ExportIcon}
            alt="Export to CSV"
            className={`w-14 h-14 cursor-pointer ${
              !dataFetched ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => dataFetched && exportToCSV()}
          />
        </CSVLink>
      </div>
    </div>
  );
};

export default CashewReports;
